import React, { useEffect, useContext, useState } from 'react';
import AuthContext from '../../../context/auth-context'
import { Row, Container, Col, Table } from 'react-bootstrap';
import API from '../../../API'
import { FaChevronLeft } from 'react-icons/fa'
import { Button, TableSortLabel, TextField, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import useStyles from '../../../css/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import PropTypes from "prop-types";
import moment from 'moment-timezone'
import { stableSort, getSorting } from '../../../Helper/Sorting'
import DialogInfo from '../../../Dialog/DialogInfo'
import { numberWithCommas } from '../../../Helper/Number'


const StockDetail = (props) => {
    const classes = useStyles();
    const perusahaanID = props.match.params.perusahaanID;
    const [isReload, setIsReload] = useState(true)
    const { sessionid, username } = useContext(AuthContext);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("namabarang");
    const [listLaporan, setlistLaporan] = useState([])
    const [listLaporanOri, setListLaporanOri] = useState([])
    const [openInfo, setOpenInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [isDetail, setIsDetail] = useState(false)
    const [formKeyword, setFormKeyword] = useState(null)
    const [formPilihGudang, setFormPilihGudang] = useState(false)
    const [formGudang, setFormGudang] = useState(null)
    const [listGudang, setListGudang] = useState([])
    const [namaGudang, setNamaGudang] = useState()
    const [state, setState] = useState({
        kodebarang: '',
        namabarang: '',
        stok: '',
        merek: '',
        deskripsi: '',
        namagroup: '',
        kategori: '',
        satuan: '',
        harga: '',
        harga2: '',
        harga3: '',
    })
    const goBack = () => {
        props.history.push(`/menu/${perusahaanID}/Report`)
    }

    useEffect(() => {
        var render = true
        const data = {
            "sessionid": sessionid,
            "username": username,
            perusahaanID: perusahaanID,
        }
        const url = "report/stockdetail/gudang.php"
        API(data, url).then(res => {
            if (res.errors) throw res.errors;
            if (res.success == false) {
                setOpenInfo(true)
                setDialogtext(`<p>${res.info}<p/>`)
                return;
            }
            setListGudang(res)
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setIsReload(false);
        })
        return () => {
            render = false;
        };
    }, [sessionid, username, isReload])
    const lihatLaporan = () => {
        const data = {
            "sessionid": sessionid,
            "username": username,
            "perusahaanID": perusahaanID,
            "keyword": formKeyword,
            "gudang": formGudang,
        }
        const url = "report/stockdetail/index.php"
        API(data, url).then(res => {
            if (res.errors) throw res.errors;
            if (res.success == false) {
                setOpenInfo(true)
                setDialogtext(`<p>${res.info}<p/>`)
                return;
            }
            setlistLaporan(res)
            setListLaporanOri(res)
            setIsDetail(true)
        }).catch(err => {
            console.error(err);
        })
    }
    const headRows = [
        { id: "kodebarang", label: "Kode Barang" },
        { id: "namabarang", label: "Nama Barang" },
        { id: "stok", label: "Stok" },
        { id: "deskripsi", label: "Deskripsi", },
        { id: "namamerk", label: "Merek" },
        { id: "namadevision", label: "Nama Group" },
        { id: "namadevisioncoa", label: "Kategori" },
        { id: "sat1", label: "Satuan" },
        { id: "jualidr", label: "Harga" },
        { id: "harga2", label: "Harga2" },
        { id: "harga3", label: "Harga3" },

    ];
    const EnhancedTableHead = (props) => {
        const {
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (

            <thead>
                <tr className="colorWhite bgHeaderTabel tabelCenter">
                    {headRows.map(row => (
                        <th key={row.id} >
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                            </TableSortLabel>
                        </th>
                    ))}
                </tr>
            </thead>
        );
    }
    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired
    };
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    const handleKeyword = (val) => setFormKeyword(val.target.value);
    const handleGudang = (val) => {
        setFormGudang(val.target.value)
        const g = listGudang.filter(el => {
            return el.kodegudang === val.target.value
        })
        setNamaGudang(g[0].namagudang)
    }
    const handleChangeCheckBox = name => event => {
        setFormPilihGudang(event.target.checked)
        if (!event.target.checked) setFormGudang(null)
    }
    const handleUbahCari = () => {
        setIsDetail(false)
    }
    const handleChangeCari = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        const newValue = { ...state };
        newValue[key] = value;
        setState(newValue);
        if(value.length > 0){
            const filter = listLaporanOri.filter(el => {
                return el.kodebarang.toUpperCase().match(value.toUpperCase()) ||
                el.namabarang.toUpperCase().match(value.toUpperCase()) ||
                el.stok.toUpperCase().match(value.toUpperCase()) ||
                el.deskripsi.toUpperCase().match(value.toUpperCase()) ||
                el.namamerk.toUpperCase().match(value.toUpperCase()) ||
                el.namadevision.toUpperCase().match(value.toUpperCase()) ||
                el.namadevisioncoa.toUpperCase().match(value.toUpperCase()) ||
                el.sat1.toUpperCase().match(value.toUpperCase()) ||
                el.jualidr.toUpperCase().match(value.toUpperCase()) ||
                el.harga2.toUpperCase().match(value.toUpperCase()) ||
                el.harga3.toUpperCase().match(value.toUpperCase())
            })
            setlistLaporan(filter)
        }else {
            setlistLaporan(listLaporanOri)
        }
    }
    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Laporan Stock Detail</h3>
                    </Col>
                </Row>
                {!isDetail && (
                    <Row>
                        <Col xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="Keyword"
                                label="Masukan Keyword Barang:"
                                name="Keyword"
                                onChange={handleKeyword}
                                value={formKeyword}
                            />
                        </Col>
                        <Col xs={12}>
                            <FormControlLabel control={<Checkbox checked={formPilihGudang}
                                onChange={handleChangeCheckBox('pilihGudang')}
                            />} label="Pilih Gudang" />
                        </Col>
                        {formPilihGudang && (
                            <Col xs={12}>
                                <FormControl fullWidth={true}>
                                    <InputLabel>List Gudang</InputLabel>
                                    <Select
                                        required
                                        value={formGudang}
                                        onChange={handleGudang}
                                    >
                                        {listGudang.map(el => (
                                            <MenuItem key={el.kodegudang} value={el.kodegudang}>{el.namagudang}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        )}
                        <Col xs={12} className="text-center"><br />
                            <Button variant="contained" color="primary" onClick={lihatLaporan}>Lihat Laporan</Button>
                        </Col>
                    </Row>
                )}
                {isDetail && (
                    <Row>
                        <Col xs={12}>
                            {formKeyword && (
                                <p>Pencarian dengan Keyword : {formKeyword}</p>
                            )}
                            {formGudang && (
                                <p>Pencarian dengan Gudang : {namaGudang}</p>
                            )}
                            <span className="ubahPencarian" onClick={handleUbahCari}>(Ubah Pencarian)</span>
                        </Col>
                        <Col xs={12}>
                            <Table striped bordered hover responsive>
                                <EnhancedTableHead
                                    onRequestSort={handleRequestSort}
                                    order={order}
                                    orderBy={orderBy}
                                />
                                <tbody className={`${classes.contentBody}`}>
                                    <tr>
                                        <td>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="kodebarang"
                                                name="kodebarang"
                                                variant="outlined"
                                                value={state.kodebarang}
                                                onChange={handleChangeCari}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="namabarang"
                                                name="namabarang"
                                                variant="outlined"
                                                value={state.namabarang}
                                                onChange={handleChangeCari}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="stok"
                                                name="stok"
                                                variant="outlined"
                                                value={state.stok}
                                                onChange={handleChangeCari}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="deskripsi"
                                                name="deskripsi"
                                                variant="outlined"
                                                value={state.deskripsi}
                                                onChange={handleChangeCari}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="namamerk"
                                                name="namamerk"
                                                variant="outlined"
                                                value={state.namamerk}
                                                onChange={handleChangeCari}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="namadevision"
                                                name="namadevision"
                                                variant="outlined"
                                                value={state.namadevision}
                                                onChange={handleChangeCari}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="namadevisioncoa"
                                                name="namadevisioncoa"
                                                variant="outlined"
                                                value={state.namadevisioncoa}
                                                onChange={handleChangeCari}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="sat1"
                                                name="sat1"
                                                variant="outlined"
                                                value={state.sat1}
                                                onChange={handleChangeCari}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="jualidr"
                                                name="jualidr"
                                                variant="outlined"
                                                value={state.jualidr}
                                                onChange={handleChangeCari}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="harga2"
                                                name="harga2"
                                                variant="outlined"
                                                value={state.harga2}
                                                onChange={handleChangeCari}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="harga3"
                                                name="harga3"
                                                variant="outlined"
                                                value={state.harga3}
                                                onChange={handleChangeCari}
                                            />
                                        </td>
                                    </tr>
                                    {stableSort(listLaporan, getSorting(order, orderBy))
                                        .map((data, index) => {
                                            return (
                                                <tr key={index} className="colorWhite">
                                                    <td >
                                                        {data.kodebarang}
                                                    </td>
                                                    <td >
                                                        {data.namabarang}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.stok)}
                                                    </td>
                                                    <td >
                                                        {data.deskripsi}
                                                    </td>
                                                    <td >
                                                        {data.namamerk}
                                                    </td>
                                                    <td >
                                                        {data.namadevision}
                                                    </td>
                                                    <td >
                                                        {data.namadevisioncoa}
                                                    </td>
                                                    <td >
                                                        {data.sat1}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.jualidr)}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.harga2)}
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithCommas(data.harga3)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                )}
            </Container>
            {openInfo && (
                <DialogInfo open={openInfo} setOpen={setOpenInfo} text={dialogtext} />
            )}
        </React.Fragment>
    );
}
export default StockDetail;