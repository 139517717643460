import React, { useState, useEffect } from "react";
import {
    Button, Toolbar, Typography, IconButton, Dialog, AppBar, Slide, Container
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col, Table } from "react-bootstrap";
import DoneIcon from '@material-ui/icons/Done';
import API from '../../../API'
import ModalInfo from '../../../Dialog/Modal.Info'
import ModalConfirm from '../../../Dialog/Modal.Confirm'
import ModalPrompt from '../../../Dialog/Modal.Prompt'
import moment from 'moment';

const QuotationApprovalDet = (props) => {
    const classes = useStyles();
    const kodeTrans = props.kodeTrans
    const perusahaanID = props.perusahaanID
    const sessionid = props.sessionid
    const username = props.username
    const [isReload, setIsReload] = useState(true)
    const [data, setData] = useState({})
    const [modalInfoOpen, setModalInfoOpen] = useState(false)
    const [modalConfirmAppr, setModalConfirmAppr] = useState(false)
    const [modalConfirmRej, setModalConfirmRej] = useState(false)
    const [modalPromptRejectRemark, setModalPromptRejectRemark] = useState(false)
    const [modalText, setModalText] = useState()
    const [listData, setListData] = useState([])

    const numberWithCommas = (x) => {
        if (!x) x = 0;
        const a = parseFloat(Math.round(x * 100) / 100).toFixed(2)
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    useEffect(() => {
        var render = true
        if (isReload) {
            const data = {
                "sessionid": sessionid,
                "username": username,
                "perusahaanID": perusahaanID,
                "noTrans": kodeTrans
            }
            const url = "QuotationApproval/quotationApprovalDetail.php"
            API(data, url).then(res => {
                if (res.errors) throw res.errors;
                if (res.success == false) {
                    alert(res.info)
                    return;
                }
                setData(res)
            }).catch(err => {
                console.error(err);
            })

            const dataList = {
                "sessionid": sessionid,
                "username": username,
                "perusahaanID": perusahaanID,
                "noTrans": kodeTrans
            }
            const urlList = "QuotationApproval/quotationApprovalDetailList.php"
            API(dataList, urlList).then(res => {
                if (res.errors) throw res.errors;
                if (res.success == false) {
                    alert(res.info)
                    return;
                }
                setListData(res)
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                setIsReload(false);
            })
        }
        return () => {
            render = false;
        };
    }, [sessionid, username, isReload, kodeTrans])
    const handleClose = () => {
        props.setOpen(false);
    }
    const handleApprove = () => {
        const data = {
            "sessionid": sessionid,
            "username": username,
            perusahaanID: perusahaanID,
            "noTrans": kodeTrans
        }
        const url = "QuotationApproval/accQuotationApproval.php"
        API(data, url).then(res => {
            if (res.errors) throw res.errors;
            if (res.success) {
                setModalInfoOpen(true)
                setModalText(`<p> ${res.info} </p>`)
                props.reload(true)
            }
        }).catch(err => {
            console.error(err);
        })
    }
    const handleRejectRemark = () => {
        setModalPromptRejectRemark(true)
        props.reload(true)
    }

    const handleReject = (remark) => {
    const data = {
        "sessionid": sessionid,
        "username": username,
        perusahaanID: perusahaanID,
        "noTrans": kodeTrans,
        "remark": remark,
    }
    const url = "QuotationApproval/rejectQuotationApproval.php"
    API(data, url).then(res => {
        if (res.errors) throw res.errors;
        if (res.success) {
            setModalInfoOpen(true)
            setModalText(`<p> ${res.info} </p>`)
            props.reload(true)
        }
    }).catch(err => {
        console.error(err);
    })
}

    const handleApproveConfirm = () => {
        setModalConfirmAppr(true)
        props.reload(true)
    }
    const handleRejectConfirm = () => {
        setModalConfirmRej(true)
        props.reload(true)
    }
    
    return (
        <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose} >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Sales Quotation Detail</Typography>
                </Toolbar>
            </AppBar>
            <Container className="DialogFont bodyDialog">
                <Row className={classes.root}>
                    <Col sm={6}>
                        <Table size="lg" variant="dark">
                            <tbody>
                                <tr>
                                    <td colSpan="1">Trans No</td>
                                    <td colSpan="3">: {data.notrans}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Customer Name</td>
                                    <td colSpan="3">: {data.Namacust}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Salesman Name</td>
                                    <td colSpan="3">: {data.namasalespenjualan}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Trans Date</td>
                                    <td colSpan="3">: {moment(data.tanggal).format('DD-MMM-YYYY hh:mm:ss A')}</td>
                                </tr>
                                <tr>
                                    <td colSpan="1">Subject</td>
                                    <td colSpan="3">: {data.remark}</td>
                                </tr>
                                <tr>
                                    <td>Currency</td>
                                    <td>: {data.ccyid}</td>
                                    <td>Rate</td>
                                    <td>: {data.kurs}</td>
                                </tr>
                                </tbody>
                        </Table>
                    </Col>
                    <Col sm={6}>
                        <Table size="lg" variant="dark">
                            <tbody>
                                <tr>
                                    <td>PO Ref</td>
                                    <td>: {data.poRef}</td>
                                </tr>
                                <tr>
                                    <td>Attention</td>
                                    <td>: {data.attention}</td>
                                </tr>
                                <tr>
                                    <td>Shipping Address</td>
                                    <td>: {data.shippingaddress}</td>
                                </tr>
                                <tr>
                                    <td>Requested By</td>
                                    <td>: {data.userupt}</td>
                                </tr>
                                <tr>
                                    <td>Requested</td>
                                    <td>: {moment(data.jamupt).format('DD-MMM-YYYY hh:mm:ss A')}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12}>
                        <Table striped bordered hover responsive="md">
                            <thead className="text-center">
                                <tr className="colorWhite bgHeaderTabel">
                                    <th>Item Name</th>
                                    <th>Item Remark</th>
                                    <th>Size</th>
                                    <th>Part No</th>
                                    <th>Brand Name</th>
                                    <th>Group Name</th>
                                    <th>Category Name</th>
                                    <th>Model Name</th>
                                    <th>Qty</th>
                                    <th>Unit Name</th>
                                    <th>Price</th>
                                    <th>Min Price</th>
                                    <th>Sales Price</th>
                                    <th>Amount</th>
                                    <th>Discount</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listData.map((el, index) => {
                                    return (
                                        <tr className="colorWhite" key={index}>
                                            <td>{el.namabarang}</td>
                                            <td>{el.itemremark}</td>
                                            <td>{el.size}</td>
                                            <td>{el.partnumber}</td>
                                            <td>{el.BrandName}</td>
                                            <td>{el.GroupName}</td>
                                            <td>{el.CategoryName}</td>
                                            <td>{el.ModelName}</td>
                                            <td className="text-center">{el.qty}</td>
                                            <td>{el.namasatuan}</td>
                                            <td className="text-right">{numberWithCommas(el.harga)}</td>
                                            <td className="text-right">{numberWithCommas(el.rfqminprice)}</td>
                                            <td className="text-right">{numberWithCommas(el.rfqsalesprice)}</td>
                                            <td className="text-right">{numberWithCommas(el.jumlah)}</td>
                                            <td className="text-right">{numberWithCommas(el.diskon)}</td>
                                            <td className="text-right">{numberWithCommas(el.totalakhir)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12} className="text-center"><br />
                        <Button variant="contained" color="primary" onClick={handleApproveConfirm}><DoneIcon /> Approve</Button> <Button variant="contained" color="primary" className="btnReject" onClick={handleRejectConfirm}><CloseIcon /> Reject</Button>
                    </Col>
                </Row>
            </Container>
            {modalInfoOpen && (
                <ModalInfo open={modalInfoOpen} setOpen={setModalInfoOpen} text={modalText} Ok={handleClose} />
            )}
            {modalConfirmAppr && (
                <ModalConfirm open={modalConfirmAppr} setOpen={setModalConfirmAppr} text={'Do You Want to Approve?'} Yes={handleApprove} />
            )}
            {modalConfirmRej && (
                <ModalConfirm open={modalConfirmRej} setOpen={setModalConfirmRej} text={'Do You Want to Reject?'} Yes={handleRejectRemark} />
            )}
            {modalPromptRejectRemark && (
                <ModalPrompt open={modalPromptRejectRemark} setOpen={setModalPromptRejectRemark} text={'Input the Reject Remark?'} Ok={handleReject}/>
            )}
        </Dialog>
    )
}
export default QuotationApprovalDet;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
    },
}));