import React, { useEffect, useContext, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa'

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%',
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));


const MenuMaster = (props) => {
    const perusahaanID = props.match.params.perusahaanID;
    const jenisMenu = props.match.params.jenisMenu;
    const classes = useStyles();
    const goBack = () => {
        props.history.push(`/pilih-company`)
    }
    return (
        <React.Fragment>
            <CssBaseline />
            <main>
                <Container className={classes.cardGrid}>
                <h3 className="pilihOutlet border-bottom nameMenus"> <FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />List Menu</h3>
                    <Grid container spacing={4} style={{ 'marginTop': '25px' }}>
                            <React.Fragment>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Card className={classes.card}>
                                        <CardContent className={classes.cardContent}>
                                            <Typography gutterBottom variant="h6" component="h2">Transaction</Typography>
                                        </CardContent>
                                        <CardActions className="bgPilihOutlet">
                                            <Link className="hrefOutlet" to={`/menu/${perusahaanID}/Transaction`}> Choose</Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Card className={classes.card}>
                                        <CardContent className={classes.cardContent}>
                                            <Typography gutterBottom variant="h6" component="h2">Report</Typography>
                                        </CardContent>
                                        <CardActions className="bgPilihOutlet">
                                            <Link className="hrefOutlet" to={`/menu/${perusahaanID}/Report`}> Choose</Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </React.Fragment>
                    </Grid>
                </Container>
            </main>
        </React.Fragment>
    );
}
export default MenuMaster;