import React, { useEffect, useContext, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import AuthContext from '../../context/auth-context'
import {  Container } from 'react-bootstrap';
import API from '../../API'
import DialogInfo from '../../Dialog/DialogInfo'
import { FaChevronLeft } from 'react-icons/fa'

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%',
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));


const Menu = (props) => {
    const perusahaanID = props.match.params.perusahaanID;
    const jenisMenu = props.match.params.jenisMenu;
    const classes = useStyles();
    const [isReload, setIsReload] = useState(true)
    const { sessionid, username } = useContext(AuthContext);
    const [listCompany, setListCompany] = useState([])
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    if (jenisMenu == "Transaction") {
        var menuID = 2
    } else if (jenisMenu == "Report") {
        var menuID = 1
    }

    useEffect(() => {
        var render = true
        if (isReload) {
            const data = {
                "sessionid": sessionid,
                "username": username,
                "jenisMenu": menuID,
                perusahaanID: perusahaanID

            }
            const url = "hakakses/menu.php"
            API(data, url).then(res => {
                if (res.errors) throw res.errors;
                if (res.success == false) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${res.info}<p/>`)
                    return;
                }
                setListCompany(res)
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                setIsReload(false);
            })
        }
        return () => {
            render = false;
        };
    }, [sessionid, username, isReload])
    const goBack = () => {
        props.history.push(`/menu/${perusahaanID}`)
    }
    return (
        <React.Fragment>
            <CssBaseline />
            <main>
                <Container className={classes.cardGrid}>
                    <h3 className="pilihOutlet nameMenus border-bottom"> <FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />List of {jenisMenu} Menu</h3>
                    <Grid container spacing={4}  style={{ 'marginTop': '25px' }}>
                    {listCompany.map((card, idx) => (
                            <Grid item key={card.kodeoutlet} xs={12} sm={6} md={4} key={idx}>
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography gutterBottom variant="h6" component="h2">{card.namaMenu}</Typography>
                                    </CardContent>
                                    <CardActions className="bgPilihOutlet">
                                        <Link className="hrefOutlet" to={`/${perusahaanID}/${jenisMenu}/${card.url}`}> Choose Menu</Link>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
        </React.Fragment>
    );
}
export default Menu;