/* eslint-disable no-mixed-operators */
import React, { useState } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import { NavLink, Link } from 'react-router-dom'

const MyNavbar = (props) => {
  const [navExpanded, setNavExpanded] = useState(false)
  return (
    <Navbar expand="lg" bg="dark" className="fixed-top navbar" onToggle={setNavExpanded.bind(true)} expanded={navExpanded}>
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img alt="logo" width="250" src={`/icon/${process.env.REACT_APP_COMPANY}/logo.png`}/>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" onClick={setNavExpanded.bind(this, false)}>
            <NavLink className="nav-link" to="/">Home</NavLink>
            {props.isLogin &&
              <React.Fragment>
                <NavLink className="nav-link" to="/pilih-company">Select Company</NavLink>
                <NavLink className="nav-link" to="#">Profil</NavLink>
              </React.Fragment>
              ||
              <React.Fragment>
                <NavLink className="nav-link" to="/login">Login</NavLink>
              </React.Fragment>
            }

          </Nav>
          {props.isLogin &&
            <Nav>
            <NavLink className="nav-link" to="/login" onClick={props.onLogout.bind(this)}>Log Out</NavLink>
            </Nav>
          }

        </Navbar.Collapse>

      </Container>

    </Navbar>
  )
}

export default MyNavbar;