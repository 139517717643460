import React, { useEffect, useContext, useState } from 'react';
import AuthContext from '../../../context/auth-context'
import { Row, Container, Col } from 'react-bootstrap';
import API from '../../../API'
import DialogInfo from '../../../Dialog/DialogInfo'
import { FaChevronLeft } from 'react-icons/fa'
import { Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, FormControlLabel, Switch, InputBase, Checkbox, Toolbar, Typography, Tooltip, IconButton, Grid } from '@material-ui/core';
import useStyles from '../../../css/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import PropTypes from "prop-types";
import PageviewIcon from '@material-ui/icons/Pageview';
import ModalApprovalDet from './PurchaseRequestDet.dialog';
import moment from 'moment';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const PurchaseRequest = (props) => {
    const classes = useStyles();
    const perusahaanID = props.match.params.perusahaanID;
    const [isReload, setIsReload] = useState(true)
    const { sessionid, username } = useContext(AuthContext);
    const [noDataPending, setNoDataPending] = useState(false)
    const [noDataApproved, setNoDataApproved] = useState(false)
    const [noDataRejected, setNoDataRejected] = useState(false)
    const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("idcust");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rowsPending, setRowsPending] = useState([])
    const [rowsApproved, setRowsApproved] = useState([])
    const [rowsRejected, setRowsRejected] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [kodeTrans, setKodeTrans] = useState()

    const goBack = () => {
        props.history.push(`/menu/${perusahaanID}/Transaction`)
    }

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const emptyRowsPending = rowsPerPage - Math.min(rowsPerPage, rowsPending.length - page * rowsPerPage);
    const emptyRowsApproved = rowsPerPage - Math.min(rowsPerPage, rowsApproved.length - page * rowsPerPage);
    const emptyRowsRejected = rowsPerPage - Math.min(rowsPerPage, rowsRejected.length - page * rowsPerPage);

    const loadingRow = [0, 1, 2, 3, 4];

    const headRows = [
        { id: "notransaksi", numeric: false, disablePadding: false, label: "Trans No" },
        { id: "remark", numeric: true, disablePadding: false, label: "Remark" },
        { id: "userrequest", numeric: true, disablePadding: false, label: "Requested By" },
        { id: "jamrequest", numeric: true, disablePadding: false, label: "Requested" },
    ];

    const EnhancedTableHead = (props) => {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: ' 25px' }}>Action</TableCell>
                    {headRows.map(row => (
                        <TableCell key={row.id}>
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                                {orderBy === row.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired
    };
    useEffect(() => {
        var render = true
        if (isReload) {
            setRowsPending([]);
            setNoDataPending(false);
            const data = {
                "sessionid": sessionid,
                "username": username,
                perusahaanID: perusahaanID,
                statusid: 1,
            }
            const urlPending = "PurchaseRequest/listPurchaseRequest.php"
            API(data, urlPending).then(res => {
                if (res.errors) throw res.errors;
                if (res.success === false) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${res.info}<p/>`)
                    return;
                }
                if (res.length <= 0) {
                    setNoDataPending(true);
                    return
                }
                setRowsPending(res)
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                setIsReload(false);
            })
            setRowsApproved([]);
            setNoDataApproved(false);
            const dataApproved = {
                "sessionid": sessionid,
                "username": username,
                perusahaanID: perusahaanID,
                statusid: 23,
            }
            const urlApproved = "PurchaseRequest/listPurchaseRequest.php"
            API(dataApproved, urlApproved).then(res => {
                if (res.errors) throw res.errors;
                if (res.success === false) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${res.info}<p/>`)
                    return;
                }
                if (res.length <= 0) {
                    setNoDataApproved(true);
                    return
                }
                setRowsApproved(res)
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                setIsReload(false);
            })
            setRowsRejected([])
            setNoDataRejected(false);
            const dataRejected = {
                "sessionid": sessionid,
                "username": username,
                perusahaanID: perusahaanID,
                statusid: 24,
            }
            const urlRejected = "PurchaseRequest/listPurchaseRequest.php"
            API(dataRejected, urlRejected).then(res => {
                if (res.errors) throw res.errors;
                if (res.success === false) {
                    setOpenConfirmInfo(true)
                    setDialogtext(`<p>${res.info}<p/>`)
                    return;
                }
                if (res.length <= 0) {
                    setNoDataRejected(true);
                    return
                }
                setRowsRejected(res)
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                setIsReload(false);
            })
        }
        return () => {
            render = false;
        };
    }, [sessionid, username, isReload])

    const handleDetail = (data) => {
        setKodeTrans(data.notrans)
        setModalOpen(true)
    }

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />List Purchase Request</h3>
                        <div className={classes.root}>
                            <Tabs defaultActiveKey="pending" id="menu-tab">
                                <Tab eventKey="pending" title="Pending">
                                    <Paper className={classes.paper}>
                                        <div className={classes.tableWrapper}>
                                            <Table
                                                className="table"
                                                aria-labelledby="tableTitle"
                                            >
                                                <EnhancedTableHead
                                                    classes={classes}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={rowsPending.length}
                                                />
                                                <TableBody>
                                                    {noDataPending && (
                                                        <TableRow style={{ height: 15 * emptyRowsPending }}>
                                                            <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                                                <b>There is no data</b>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    {rowsPending.length === 0 && !noDataPending && loadingRow.map(el => (
                                                        <TableRow
                                                            key={el.toString()}
                                                            style={{ height: 50 }}
                                                        >
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" >
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                    }
                                                    {stableSort(rowsPending, getSorting(order, orderBy))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((rowPending, index) => {
                                                            const labelId = `enhanced-table-checkbox-${index}`;
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                >
                                                                    <TableCell>
                                                                        <Button title="Detail" variant="contained" className="bgBtnDetail" onClick={handleDetail.bind(this, rowPending)}><PageviewIcon /></Button>
                                                                    </TableCell>
                                                                    <TableCell className="rowAlignCenter">
                                                                        {rowPending.notrans}
                                                                    </TableCell>
                                                                    <TableCell className="rowAlignCenter">
                                                                        {rowPending.remark}
                                                                    </TableCell>
                                                                    <TableCell className="rowAlignCenter">
                                                                        {rowPending.userrequest}
                                                                    </TableCell>
                                                                    <TableCell className="rowAlignCenter">
                                                                        {moment(rowPending.jamrequest).format('DD-MMM-YYYY hh:mm:ss A')}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {emptyRowsPending > 0 && (
                                                        <TableRow style={{ height: 49 * emptyRowsPending }}>
                                                            <TableCell colSpan={7} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            component="div"
                                            count={rowsPending.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            backIconButtonProps={{
                                                "aria-label": "previous page"
                                            }}
                                            nextIconButtonProps={{
                                                "aria-label": "next page"
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </Tab>
                                <Tab eventKey="approved" title="Approved">
                                    <Paper className={classes.paper}>
                                        <div className={classes.tableWrapper}>
                                            <Table
                                                className="table"
                                                aria-labelledby="tableTitle"
                                            >
                                                <EnhancedTableHead
                                                    classes={classes}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={rowsApproved.length}
                                                />
                                                <TableBody>
                                                    {noDataApproved && (
                                                        <TableRow style={{ height: 15 * emptyRowsApproved }}>
                                                            <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                                                <b>There is no data</b>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    {rowsApproved.length == 0 && !noDataApproved && loadingRow.map(el => (
                                                        <TableRow
                                                            key={el.toString()}
                                                            style={{ height: 50 }}
                                                        >
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" >
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                    }
                                                    {stableSort(rowsApproved, getSorting(order, orderBy))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((rowApproved, index) => {
                                                            const labelId = `enhanced-table-checkbox-${index}`;
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                >
                                                                    <TableCell>
                                                                        <Button title="Detail" variant="contained" className="bgBtnDetail" onClick={handleDetail.bind(this, rowApproved)}><PageviewIcon /></Button>
                                                                    </TableCell>
                                                                    <TableCell className="rowAlignCenter">
                                                                        {rowApproved.notrans}
                                                                    </TableCell>
                                                                    <TableCell className="rowAlignCenter">
                                                                        {rowApproved.remark}
                                                                    </TableCell>
                                                                    <TableCell className="rowAlignCenter">
                                                                        {rowApproved.userrequest}
                                                                    </TableCell>
                                                                    <TableCell className="rowAlignCenter">
                                                                        {moment(rowApproved.jamrequest).format('DD-MMM-YYYY hh:mm:ss A')}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {emptyRowsApproved > 0 && (
                                                        <TableRow style={{ height: 49 * emptyRowsApproved }}>
                                                            <TableCell colSpan={7} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            component="div"
                                            count={rowsApproved.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            backIconButtonProps={{
                                                "aria-label": "previous page"
                                            }}
                                            nextIconButtonProps={{
                                                "aria-label": "next page"
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </Tab>
                                <Tab eventKey="rejected" title="Rejected">
                                <Paper className={classes.paper}>
                                        <div className={classes.tableWrapper}>
                                            <Table
                                                className="table"
                                                aria-labelledby="tableTitle"
                                            >
                                                <EnhancedTableHead
                                                    classes={classes}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={rowsRejected.length}
                                                />
                                                <TableBody>
                                                    {noDataRejected && (
                                                        <TableRow style={{ height: 15 * emptyRowsRejected }}>
                                                            <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                                                <b>There is no data</b>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    {rowsRejected.length == 0 && !noDataRejected && loadingRow.map(el => (
                                                        <TableRow
                                                            key={el.toString()}
                                                            style={{ height: 50 }}
                                                        >
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" >
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Skeleton />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                    }
                                                    {stableSort(rowsRejected, getSorting(order, orderBy))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((rowRejected, index) => {
                                                            const labelId = `enhanced-table-checkbox-${index}`;
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                >
                                                                    <TableCell>
                                                                        <Button title="Detail" variant="contained" className="bgBtnDetail" onClick={handleDetail.bind(this, rowRejected)}><PageviewIcon /></Button>
                                                                    </TableCell>
                                                                    <TableCell className="rowAlignCenter">
                                                                        {rowRejected.notrans}
                                                                    </TableCell>
                                                                    <TableCell className="rowAlignCenter">
                                                                        {rowRejected.remark}
                                                                    </TableCell>
                                                                    <TableCell className="rowAlignCenter">
                                                                        {rowRejected.userrequest}
                                                                    </TableCell>
                                                                    <TableCell className="rowAlignCenter">
                                                                        {moment(rowRejected.jamrequest).format('DD-MMM-YYYY hh:mm:ss A')}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {emptyRowsRejected > 0 && (
                                                        <TableRow style={{ height: 49 * emptyRowsRejected }}>
                                                            <TableCell colSpan={7} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            component="div"
                                            count={rowsRejected.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            backIconButtonProps={{
                                                "aria-label": "previous page"
                                            }}
                                            nextIconButtonProps={{
                                                "aria-label": "next page"
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Container>

            <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
            {modalOpen && (
                <ModalApprovalDet open={modalOpen} setOpen={setModalOpen} sessionid={sessionid} perusahaanID={perusahaanID} username={username} kodeTrans={kodeTrans} reload={setIsReload} />
            )}
        </React.Fragment>
    );
}
export default PurchaseRequest;