import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const ModalConfirm = (props) => {
    const classes = useStyles();
    const handleNo = () => {
        props.setOpen(false);
    }
    const handleYes = () => {
        props.setOpen(false);
        props.Yes()
    }
    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
            open={props.open}
            onClose={handleNo}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.open}>
                <div className={classes.paper}>
                    <DialogTitle className="colorWhite">Homan System says</DialogTitle>
                    <DialogContent className="colorWhite">
                        <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleYes} color="primary" autoFocus>Yes</Button>
                        <Button onClick={handleNo} color="primary" autoFocus>No</Button>
                    </DialogActions>
                </div>
            </Fade>

        </Modal>
    )
}
export default ModalConfirm;

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        backgroundColor: '#282a2f',
        width: 400,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));
