import React, { useEffect, useContext, useState } from 'react';
import AuthContext from '../../../context/auth-context'
import { Row, Container, Col, Table } from 'react-bootstrap';
import API from '../../../API'
import { FaChevronLeft } from 'react-icons/fa'
import { Button, TableSortLabel } from '@material-ui/core';
import useStyles from '../../../css/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import PropTypes from "prop-types";
import PageviewIcon from '@material-ui/icons/Pageview';
import moment from 'moment-timezone'
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { stableSort, getSorting } from '../../../Helper/Sorting'
import DialogInfo from '../../../Dialog/DialogInfo'
import {numberWithCommas} from '../../../Helper/Number'

const tglSkrg = moment()
const tglSatu = moment(tglSkrg).tz("Asia/Jakarta").set({
    date: 1,
    hour: 0,
    minute: 0,
    second: 0
});

const JrReport = (props) => {
    const classes = useStyles();
    const perusahaanID = props.match.params.perusahaanID;
    const [isReload, setIsReload] = useState(true)
    const { sessionid, username } = useContext(AuthContext);
    const [tglDari, setTglDari] = useState(tglSatu)
    const [tglSampai, setTglSampai] = useState(tglSkrg)
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("nama");
    const [listLaporan, setlistLaporan] = useState([])
    const [openInfo, setOpenInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    var urut = 0
    const goBack = () => {
        props.history.push(`/menu/${perusahaanID}/Report`)
    }

    useEffect(() => {
        var render = true
        const data = {
            "sessionid": sessionid,
            "username": username,
            perusahaanID: perusahaanID,
            tanggalSampai:  moment(tglSampai).tz("Asia/Jakarta").set({hour: 23, minute: 59,second: 59}),
            statusid: 1,
        }
        const url = "report/jrreport/index.php"
        API(data, url).then(res => {
            if (res.errors) throw res.errors;
            if (res.success == false) {
                setOpenInfo(true)
                setDialogtext(`<p>${res.info}<p/>`)
                return;
            }
            const convert =[]
            res.map(el => {
                convert.push({
                   nama: el.nama,
                   amount: el.amount * 1 
                })
            })
            setlistLaporan(convert)
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setIsReload(false);
        })
        return () => {
            render = false;
        };
    }, [sessionid, username, isReload])
    const handleChangeTglDari = (ev) => {
        setTglDari(ev)
    }
    const handleChangeTglSampai = (ev) => {
        setTglSampai(ev)
    }
    const lihatLaporan = () => {
        setIsReload(true)
    }
    const headRows = [
        {
            id: "nama", label: "Name"
        },
        {
            id: "amount", label: "Amount",
        }

    ];
    const EnhancedTableHead = (props) => {
        const {
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (

            <thead>
                <tr className="colorWhite bgHeaderTabel tabelCenter">
                    <th className="text-center" style={{ width: '5px' }}>Urut</th>
                    <th style={{ width: '10px' }}>Detail</th>
                    {headRows.map(row => (
                        <th key={row.id} >
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                            </TableSortLabel>
                        </th>
                    ))}
                </tr>
            </thead>
        );
    }
    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired
    };
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />GL Report</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                            <KeyboardDatePicker
                                fullWidth={true}
                                margin="normal"
                                id="tanggal"
                                label="Tanggal Dari"
                                format="DD-MM-YYYY"
                                value={tglDari}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                onChange={handleChangeTglDari}
                                ampm={false}
                                disableFuture
                            />
                        </MuiPickersUtilsProvider>
                    </Col>
                    <Col xs={12} md={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                            <KeyboardDatePicker
                                fullWidth={true}
                                margin="normal"
                                id="tanggal"
                                label="Tanggal Sampai"
                                format="DD-MM-YYYY"
                                value={tglSampai}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{ color: '#000 !important' }}
                                onChange={handleChangeTglSampai}
                                ampm={false}
                            />
                        </MuiPickersUtilsProvider>
                    </Col>
                    <Col xs={12} md={2} className="text-center"><br />
                        <Button variant="contained" color="primary" onClick={lihatLaporan}>Lihat</Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Table striped bordered hover responsive="lg">
                            <EnhancedTableHead
                                onRequestSort={handleRequestSort}
                                order={order}
                                orderBy={orderBy}
                            />
                            <tbody className={`${classes.contentBody}`}>
                                {stableSort(listLaporan, getSorting(order, orderBy))
                                    .map((data, index) => {
                                        urut++
                                        return (
                                            <tr key={index} className="colorWhite">
                                                <td className="text-center"> {urut}</td>
                                                <td className="text-center">
                                                    <Button title="Detail" variant="contained" className="btnRiwayatTrans"><PageviewIcon /></Button>
                                                </td>
                                                <td >
                                                    {data.nama}
                                                </td>
                                                <td className="text-right">
                                                    {numberWithCommas(data.amount)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            {openInfo && (
                <DialogInfo open={openInfo} setOpen={setOpenInfo} text={dialogtext} />
            )}
        </React.Fragment>
    );
}
export default JrReport;