import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';

const ModalInfo = (props) => {
    const classes = useStyles();
    const handleClose = () => {
        props.setOpen(false);
        props.Ok()
    }
    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.open}
            onClose={handleClose}
            className={classes.modal}
        >
            <Fade in={props.open}>
            <div className={classes.paper}>
                <DialogTitle className="colorWhite">Homan System says</DialogTitle>
                <DialogContent className="colorWhite">
                    <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>Ok</Button>
                </DialogActions>
            </div>
            </Fade>
        </Modal>
    )
}
export default ModalInfo;
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: '#282a2f',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));