import React, { useEffect, useContext, useState } from 'react';
import AuthContext from '../../../context/auth-context'
import { Row, Container, Col, Table } from 'react-bootstrap';
import API from '../../../API'
import { FaChevronLeft } from 'react-icons/fa'
import { Button, TableSortLabel, TextField, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import useStyles from '../../../css/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import PropTypes from "prop-types";
import moment from 'moment-timezone'
import { stableSort, getSorting } from '../../../Helper/Sorting'
import DialogInfo from '../../../Dialog/DialogInfo'
import { numberWithCommas } from '../../../Helper/Number'


const LaporanPiutang = (props) => {
    const classes = useStyles();
    const perusahaanID = props.match.params.perusahaanID;
    const [isReload, setIsReload] = useState(true)
    const { sessionid, username } = useContext(AuthContext);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("namaclient");
    const [listLaporan, setlistLaporan] = useState([])
    const [listLaporanOri, setListLaporanOri] = useState([])
    const [listPeriode, setListPeriode] = useState([])
    const [listCurrencry, setListCurrencry] = useState([])
    const [openInfo, setOpenInfo] = useState(false)
    const [dialogtext, setDialogtext] = useState()
    const [isDetail, setIsDetail] = useState(false)
    const [formPeriode, setFormPeriode] = useState(null)
    const [formCurrencry, setFormCurrencry] = useState(null)
    var jan = 0, feb = 0, mar =0, apr = 0, mei =0, jun = 0, jul = 0, agu = 0, sept = 0, okt = 0, nov = 0, des = 0, total = 0
    const goBack = () => {
        props.history.push(`/menu/${perusahaanID}/Report`)
    }

    useEffect(() => {
        var render = true
        API({
            "sessionid": sessionid,
            "username": username,
            perusahaanID: perusahaanID,
        },"report/periode.php").then(res => {
            if (res.errors) throw res.errors;
            if (res.success == false) {
                setOpenInfo(true)
                setDialogtext(`<p>${res.info}<p/>`)
                return;
            }
            render &&  setListPeriode(res)
        }).catch(err => {
            console.error(err);
        })
        API({
            "sessionid": sessionid,
            "username": username,
            perusahaanID: perusahaanID,
        },"report/currency.php").then(res => {
            if (res.errors) throw res.errors;
            if (res.success == false) {
                setOpenInfo(true)
                setDialogtext(`<p>${res.info}<p/>`)
                return;
            }
            render &&  setListCurrencry(res)
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setIsReload(false);
        })
        
        return () => {
            render = false;
        };
    }, [sessionid, username, isReload])
    const lihatLaporan = () => {
        const data = {
            "sessionid": sessionid,
            "username": username,
            "perusahaanID": perusahaanID,
            "ccyid": formCurrencry,
            "Year": formPeriode,
        }
        const url = "report/piutang/index.php"
        API(data, url).then(res => {
            if (res.errors) throw res.errors;
            if (res.success == false) {
                setOpenInfo(true)
                setDialogtext(`<p>${res.info}<p/>`)
                return;
            }
            setlistLaporan(res)
            setListLaporanOri(res)
            setIsDetail(true)
        }).catch(err => {
            console.error(err);
        })
    }
    const headRows = [
        { id: "namaclient", label:"Supplier"},
        { id: "jan", label: "Jan" },
        { id: "feb", label: "Feb" },
        { id: "mar", label: "Mar", },
        { id: "apr", label: "Apr" },
        { id: "mei", label: "Mei" },
        { id: "jun", label: "Jun" },
        { id: "jul", label: "Jul" },
        { id: "agu", label: "Agu" },
        { id: "sept", label: "Sep" },
        { id: "okt", label: "Okt" },
        { id: "nov", label: "Nov" },
        { id: "des", label: "Des" },
        { id: "total", label: "Total" },

    ];
    const EnhancedTableHead = (props) => {
        const {
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (

            <thead>
                <tr className="colorWhite bgHeaderTabel tabelCenter">
                    {headRows.map(row => (
                        <th key={row.id} >
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                            </TableSortLabel>
                        </th>
                    ))}
                </tr>
            </thead>
        );
    }
    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired
    };
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    const handlePeriode = (val) => {
        setFormPeriode(val.target.value)
    }
    const handleCurrencry = (val) => {
        setFormCurrencry(val.target.value)
    }
    const handleUbahCari = () => {
        setIsDetail(false)
    }

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" />Laporan Piutang</h3>
                    </Col>
                </Row>
                {!isDetail && (
                    <Row>
                        <Col xs={12}>
                            <FormControl fullWidth={true}>
                                <InputLabel>Periode</InputLabel>
                                <Select
                                    required
                                    value={formPeriode}
                                    onChange={handlePeriode}
                                >
                                    {listPeriode.map(el => (
                                        <MenuItem key={el.tahun} value={el.tahun}>{el.tahun}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12}>
                            <FormControl fullWidth={true}>
                                <InputLabel>Currency</InputLabel>
                                <Select
                                    required
                                    value={formCurrencry}
                                    onChange={handleCurrencry}
                                >
                                    {listCurrencry.map(el => (
                                        <MenuItem key={el.ccyid} value={el.ccyid}>{el.ccyid}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={12} className="text-center"><br />
                        {(!formCurrencry || !formPeriode) && (
                            <Button variant="contained" color="primary" disabled>Lihat Laporan</Button>
                        )}
                        {formCurrencry && formPeriode && (
                            <Button variant="contained" color="primary" onClick={lihatLaporan}>Lihat Laporan</Button>
                        )}
                        </Col>
                    </Row>
                )}
                {isDetail && (
                    <Row>
                        <Col xs={12}>
                            {formPeriode && (
                                <span>Periode : {formPeriode}  <br/></span> 
                            )}
                            {formCurrencry && (
                                <span>Currencry : {formCurrencry}  <br/></span>
                            )}
                            <span className="ubahPencarian" onClick={handleUbahCari}>(Ubah Pencarian)</span>
                        </Col>
                        <Col xs={12}>
                            <Table striped bordered hover responsive>
                                <EnhancedTableHead
                                    onRequestSort={handleRequestSort}
                                    order={order}
                                    orderBy={orderBy}
                                />
                                <tbody className={`${classes.contentBody}`}>
                                    {stableSort(listLaporan, getSorting(order, orderBy))
                                        .map((data, index) => {
                                            jan += data.jan
                                            feb += data.feb
                                            mar += data.mar
                                            apr += data.apr
                                            mei += data.mei
                                            jun += data.jun
                                            jul += data.jul
                                            agu += data.agu
                                            sept += data.sept
                                            okt += data.okt
                                            nov += data.nov
                                            des += data.des
                                            total += data.total
                                            return (
                                                <tr key={index} className="colorWhite">
                                                    <td >
                                                        {data.namaclient}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.jan)}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.feb)}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.mar)}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.apr)}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.mei)}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.jun)}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.jul)}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.agu)}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.sept)}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.okt)}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.nov)}
                                                    </td>
                                                    <td >
                                                        {numberWithCommas(data.des)}
                                                    </td>
                                                    <td className="text-right">
                                                        {numberWithCommas(data.total)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr className="colorWhite">
                                            <th>Total</th>
                                            <th> {numberWithCommas(jan)}</th>
                                            <th> {numberWithCommas(feb)}</th>
                                            <th> {numberWithCommas(mar)}</th>
                                            <th> {numberWithCommas(apr)}</th>
                                            <th> {numberWithCommas(mei)}</th>
                                            <th> {numberWithCommas(jun)}</th>
                                            <th> {numberWithCommas(jul)}</th>
                                            <th> {numberWithCommas(agu)}</th>
                                            <th> {numberWithCommas(sept)}</th>
                                            <th> {numberWithCommas(okt)}</th>
                                            <th> {numberWithCommas(nov)}</th>
                                            <th> {numberWithCommas(des)}</th>
                                            <th> {numberWithCommas(total)}</th>
                                        </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                )}
            </Container>
            {openInfo && (
                <DialogInfo open={openInfo} setOpen={setOpenInfo} text={dialogtext} />
            )}
        </React.Fragment>
    );
}
export default LaporanPiutang;