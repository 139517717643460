import React, {useContext} from 'react'
import AuthContext from '../../context/auth-context'
import { Container, Row } from 'react-bootstrap';

const Home = (props)=>{
  const {username} = useContext(AuthContext);
  
  return(
  <Container>
    <Row>
    <p style={{marginTop:'20px'}}>Welcome {username}</p>
    </Row>
  </Container>
  )
}

export default Home;