const API = (data, url) => {
  const myHeader = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
  return fetch(`${process.env.REACT_APP_URL}/${url}`, {
    method: 'POST',
    headers: myHeader,
    body: JSON.stringify(data)
  }).then(res => res.json())
}
export default API;