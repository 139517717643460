import React, { useEffect, useReducer, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from './context/auth-context';
import { useCookies } from 'react-cookie';
import DialogInfo from './Dialog/DialogInfo'
import Login from './Components/Login/Login';
import Home from './Components/Home/Home';
import MyNavbar from './Components/MyNavbar/MyNavbar';
import API from './API'
import Company from './Components/Company/Company'
import menuTrans from './Components/Menu/Menu'
import QuotationApproval from './Components/Transaction/Quotation Approval/QuotationApproval'
import POApproval from './Components/Transaction/PO Approval/POApproval'
import SOApproval from './Components/Transaction/SO Approval/SOApproval'
import PurchaseRequest from './Components/Transaction/PurchaseRequest/PurchaseRequest'
import MenuMaster from './Components/Menu/MenuMaster'
import GlReport from './Components/Report/GlReport/GlReport'
import StockDetail from './Components/Report/StockDetail/StockDetail'
import LaporanHutang from './Components/Report/Hutang/hutang'
import LaporanPiutang from './Components/Report/Pituang/Piutang'

const initialValue = {
  sessionid: null,
  username: null,
  isCookieLoad: false,
  isLogin: false
}

const loginReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        sessionid: action.sessionid,
        username: action.username,
        isLogin: true
      }
    case "LOGOUT":
      return {
        ...state,
        sessionid: null,
        username: null
      }
    case "COOKIE":
      return {
        ...state,
        sessionid: action.sessionid,
        username: action.username,
        isCookieLoad: true
      }
    default: throw new Error("Invalid Action");
  }
}

function App(props) {
  const [state, dispatch] = useReducer(loginReducer, initialValue)
  const [cookies, setCookie, removeCookie] = useCookies([`${process.env.REACT_APP_COMPANY}-aplication`]);
  const [OpenConfirmInfo, setOpenConfirmInfo] = useState(false)
  const [dialogtext, setDialogtext] = useState()
  useEffect(() => {
    var render = true;
    if (cookies[`${process.env.REACT_APP_COMPANY}-aplication-auth`]) {
      const { sessionid, username } = cookies[`${process.env.REACT_APP_COMPANY}-aplication-auth`] || { username: null, sessionid: null };
      if (render) {
        //console.log(username,sessionid);
        dispatch({ type: "COOKIE", sessionid, username })
      }
    } else {
      dispatch({ type: "COOKIE", username, sessionid })

    }
    return () => {
      render = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies[`${process.env.REACT_APP_COMPANY}-aplication-auth`]]);

  const login = (username, password) => {
    const data = {
      "username": username,
      "password": password
    }
    const url = "login/login.php"
    API(data, url).then(res => {
      if (res.errors) throw res.errors;
      if(res.success){
        const sessionid = res.sessionid
        const username = res.username
        setCookie(`${process.env.REACT_APP_COMPANY}-aplication-auth`, { sessionid, username }, { path: '/' })
        dispatch({ type: "LOGIN", sessionid, username })
      }else{
        setOpenConfirmInfo(true)
        setDialogtext(res.info)
      }
    }).catch(err => {
      setOpenConfirmInfo(true)
      if (err[0].message) {
        setDialogtext(`<p>${err[0].message}<p/>`)
      } else {
        setDialogtext(`<p>Cannot process your request at this moment. Please try again later.<p/>`)
      }
    })
  }

  const logout = () => {
    const data = {
      "username": username,
      "sessionid": sessionid
    }
    const url = "login/logout.php"
    API(data, url).then(resp => {
      if (resp.errors) throw resp.errors;
      if (resp.success) {
        dispatch({ type: "LOGOUT" })
        removeCookie(`${process.env.REACT_APP_COMPANY}-aplication-auth`)
      } else {
        setOpenConfirmInfo(true)
        setDialogtext(`<p>Gagal Logout<p/>`)
      }
    }).catch(err => {
      if (err[0].message) {
        setOpenConfirmInfo(true)
        setDialogtext(`<p>${err[0].message}<p/>`)
      } else {
        setDialogtext(`<p>Cannot process your request at this moment. Please try again later.<p/>`)
      }
    })
  }

  const { username, sessionid, isCookieLoad, isLogin } = state;
  // console.log(sessionid, username)
  return (
    <Router>
      <React.Fragment>
        <AuthContext.Provider
          value={{
            sessionid,
            username,
            login,
            logout
          }}
        >
          <MyNavbar onLogout={logout} isLogin={(sessionid && username)} />
          <Switch>
            {(username && sessionid && isCookieLoad) &&
              <React.Fragment>
                <Route path="/" exact component={Home} />
                <Route path="/pilih-company" exact component={Company} />
                <Route path="/menu/:perusahaanID/:jenisMenu" exact component={menuTrans} />
                <Route path="/menu/:perusahaanID" exact component={MenuMaster} />
                {/* Transkasi */}
                <Route path="/:perusahaanID/Transaction/quotationapproval" exact component={QuotationApproval} />
                <Route path="/:perusahaanID/Transaction/poapproval" exact component={POApproval} />
                <Route path="/:perusahaanID/Transaction/soapproval" exact component={SOApproval} />
                <Route path="/:perusahaanID/Transaction/PurchaseRequest" exact component={PurchaseRequest} />

                {/* laporan */}
                <Route path="/:perusahaanID/Report/glReport" exact component={GlReport} />
                <Route path="/:perusahaanID/Report/stockdetail" exact component={StockDetail} />
                <Route path="/:perusahaanID/Report/hutang" exact component={LaporanHutang} />
                <Route path="/:perusahaanID/Report/piutang" exact component={LaporanPiutang} />


                {isLogin && <Redirect from="/login/" to="/" exact />}
              </React.Fragment>

            }
            {((!username || !sessionid) && isCookieLoad) &&
              (
                <React.Fragment>
                  <Route path="/login/" component={Login} />
                  <Redirect to="/login/" />
                </React.Fragment>
              )
            }

          </Switch>
        </AuthContext.Provider>
        <DialogInfo open={OpenConfirmInfo} setOpen={setOpenConfirmInfo} text={dialogtext} />
      </React.Fragment>

    </Router>
  );
}

export default App;
