import React, { useState }  from "react";
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const ModalPrompt = (props) => {
    const classes = useStyles();
    const [remark, setRemark] = useState();
    const handleCancel = () => {
        props.setOpen(false);
    }
    const handleOk = () => {
        if(remark == null) {
            alert('Remark cannot be empty!!!');
            return
        }
        props.setOpen(false);
        props.Ok(remark)
    }
    const handleForm = (val) => {
        setRemark(val.target.value)
    }
    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
            open={props.open}
            onClose={handleCancel}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Fade in={props.open}>
            <div className={classes.paper} >
                <DialogTitle className="colorWhite">Homan System says</DialogTitle>
                <DialogContent className="colorWhite" >
                    <DialogContentText className="colorWhite">
                    <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
                    </DialogContentText>
                    <TextareaAutosize className="colorBlack"
                    required
                    autoFocus
                    id="remark"
                    label="Remark"
                    onChange={handleForm}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOk} color="primary" >Ok</Button>
                    <Button onClick={handleCancel} color="primary">Cancel</Button>
                </DialogActions>
            </div>
            </Fade>
        </Modal>
    )
}
export default ModalPrompt;

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        backgroundColor: '#282a2f',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));
